import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { store } from "./redux/index";
import { refreshToken } from "./redux/actions/authActions";
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

const handleMouseClick = () => {
  if (store.getState().auth) {
    const { refresh, token } = store.getState().auth;
    if (window.location.origin == "http://localhost:3000") {
      // this makes my life much easier in development, and the token is somewhat public anyways
      // console.log(token)
    }
    if (refresh && token) {
      store.dispatch(refreshToken(token, refresh));
    }
  }
};

window.addEventListener("click", AwesomeDebouncePromise(handleMouseClick, 500));

export { sharedInterface, logOut } from "./sharedInterface";
export const { bootstrap, mount, unmount } = lifecycles;
