import { store } from "./redux/index";
import { setToken, setUser } from "./redux/actions/authActions";
import { LOGIN_URL } from "../constants/constants";

export function sharedInterface() {
  return store.getState().auth;
}

export async function logOut() {
  await store.dispatch(setToken(null));
  await store.dispatch(setUser(null));
  window.location.href = window.location.origin + LOGIN_URL;
}
