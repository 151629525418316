import React from "react";
import LoginLayout from "./loginLayout";
import { store, persistor } from "./redux/index";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export default function Root(props) {
  return (
    <section>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
            <LoginLayout />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </section>
  );
}
