import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/combinedReducers";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "bitreel_auth",
  storage,
};

const createMiddleware = applyMiddleware(ReduxThunk)(createStore);
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createMiddleware(persistedReducer);
export const persistor = persistStore(store);
