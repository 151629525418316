import {
  SET_TOKEN,
  SET_USER,
  SET_REFRESH,
  SET_USER_LOADING,
} from "../../../constants/authConstants";

import ApiService from "../../service/apiService";

export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    token,
  };
};

export const setRefresh = (refresh) => {
  return {
    type: SET_REFRESH,
    refresh,
  };
};

export const setUser = (user) => {
  return {
    type: SET_USER,
    user,
  };
};

export const getUserLoading = (isLoading) => {
  return {
    type: SET_USER_LOADING,
    isLoading,
  };
};
export const getMe = (token) => async (dispatch) => {
  try {
    dispatch(getUserLoading(true));
    const getMe = await ApiService.getMe(token);
    dispatch(setUser(getMe.data));
    return getMe.data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(getUserLoading(false));
  }
};

export const refreshToken = (token, refresh) => async (dispatch) => {
  try {
    const getNewToken = await ApiService.postRefresh(token, refresh);
    dispatch(setToken(getNewToken.data));
    return getNewToken.data;
  } catch (error) {
    console.warn(error);
  }
};
