import axios from "axios";
import { notification } from "antd";
import {BACKEND_URL, LOGIN_URL} from "../../constants/constants";

const GET_METHOD = "get";
const POST_METHOD = "post";

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 404) {
      notification.open({
        message: "Not Found",
        description: err.response.data.message,
      });
    } else if (err.response.status === 400) {
      notification.error({
        message: "Bad Request",
        description: err.response.data.message,
      });
    } else if (err.response.status === 401) {
      notification.error({
        message: "Authentication Needed",
        description: `${err.response.data.message}. Try logging in again.`,
      });
      localStorage.clear();
      window.location.href = window.location.origin + LOGIN_URL
    } else if (err.response.status === 403) {
      notification.error({
        message: "Bad Authentication",
        description: `${err.response.data.message}. Try logging in again.`,
      });
      localStorage.clear();
      window.location.href = window.location.origin + LOGIN_URL
    } else if (err.response.status === 409) {
      notification.error({
        message: "Conflict",
        description: err.response.data.message,
      });
    } else {
      console.log(err);
      throw err;
    }
  }
);

class ApiService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  postRefresh = async (token, refresh) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/refresh`,
      headers: { Authorization: `Bearer ${token}` },
      data: { refresh },
    });
    return response;
  };

  getMe = async (token) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/me`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  };
}

export default new ApiService();
