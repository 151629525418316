import {
  SET_TOKEN,
  SET_USER,
  SET_REFRESH,
  SET_USER_LOADING,
} from "../../../constants/authConstants";
import produce from "immer";

const INITIAL_STATE = {};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TOKEN:
      const { token } = action;
      draft["token"] = token;
      break;

    case SET_USER_LOADING:
      const { isLoading } = action;
      draft["isLoading"] = isLoading;
      break;

    case SET_REFRESH:
      const { refresh } = action;
      draft["refresh"] = refresh;
      break;

    case SET_USER:
      const { user } = action;
      draft["user"] = user;
      break;

    default:
      break;
  }

  return draft;
});
