import React from "react";
import { connect } from "react-redux";
import {Button, Card, Col, Layout, Row, Space} from "antd";
const { Content } = Layout;
import qs from "query-string";
import "./loginLayout.css";
import logo from "../asset/loginLogo.png";
import {
  LOGIN_ENDPOINT,
  LOGIN_URL,
  NO_AUTH_URLS,
} from "../constants/constants";
import { withRouter } from "react-router";

import {
  setToken,
  getMe,
  setRefresh,
  getUserLoading,
} from "./redux/actions/authActions";

class LoginLayout extends React.Component {
  constructor(props) {
    super(props);
    const { getMe, auth } = this.props;
    if(auth.token) {
      getMe(auth.token);
    }
  }

  componentDidMount() {
    const { getMe, setToken, setRefresh, auth, location } = this.props;

    const { access, refresh } = qs.parse(location.search);

    if (access) {
      setToken(access);
      setRefresh(refresh);
      getMe(access);
    } else if (
      !auth.token &&
      !NO_AUTH_URLS.includes(window.location.pathname)
    ) {
      window.location.href = window.location.origin + LOGIN_URL;
    }
  }

  render() {
    if (!this.props.auth.isLoading && this.props.auth.user) {
      window.location.href = window.location.origin;
    }
    return (
      <Layout className="layout">
        <Content className="content">
          <Row className="content_row" justify="center" align="middle">
            <Card className="login_card" >
              <Row justify="center"  align="middle" style={{height: 560}}>
                <Space direction="vertical" size="middle">
                  <img className="login_logo_img" alt="bitreel" src={logo} />
                <Button
                    loading={this.props.auth.isLoading}
                    style={{width: 206}}
                    className="bitreel-primary-button login_button"
                    onClick={() => {
                      window.location.href = LOGIN_ENDPOINT;
                    }}
                    type="primary"
                  >
                    SIGN IN
                  </Button>
                </Space>
              </Row>
            </Card>
          </Row>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, {
  setToken,
  getMe,
  setRefresh,
  getUserLoading,
})(withRouter(LoginLayout));
